import { FC } from 'react';
import { Layout, Button } from 'antd';
import { footerbutton } from '../../pages/Info/styles';
const Footer: FC = () => {
  const { Footer } = Layout;

  const openFarmaconSite = () => {
    window.location.href = 'https://farmacon.gr';
  };
  const FarmaconButton = (
    <Button type="link" style={footerbutton} onClick={openFarmaconSite}>
      powered by
      <div
        style={{
          textDecoration: 'underline',
        }}
      >
        Farmacon
      </div>
    </Button>
  );
  return <Footer>{FarmaconButton}</Footer>;
};
export default Footer;
