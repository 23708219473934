import { baseColors } from '../../shared/styles/baseStyle';

export const leftTransition = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.1 } },
};

export const rightTransition = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.1 } },
};

export const upTransition = {
  hidden: { opacity: 0, y: -200 },
  visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
};

export const downTransition = {
  hidden: { opacity: 0, y: 200 },
  visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
};
export const footerbutton = {
  color: baseColors.fsBlue,
  fontWeight: 'bold',
  fontSize: 15,
};
export const layOutCol = {
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'black',
};
export const featuresContent = {
  padding: '16px 16px',
  backgroundColor: 'white',
};
export const header = {
  height: 'auto',
  backgroundColor: 'transparent',
};
export const headerImage = {
  cursor: 'pointer',
  marginTop: 30,
  marginLeft: 20,
};
export const headerMenu = {
  backgroundColor: 'transparent',
  borderBottomWidth: 0,
  fontWeight: 'bold',
};
export const handImage = {
  alignSelf: 'flex-end',
  display: 'flex',
  justifyContent: 'flex-end',
};
