import { FC } from 'react';
import {
  Typography,
  Row,
  Col,
  Image,
  Grid,
  Collapse,
  CollapseProps,
  Space,
  Divider,
} from 'antd';
import { motion } from 'framer-motion';
import { RightOutlined } from '@ant-design/icons';
import { leftTransition, rightTransition } from '../Info/styles';
import { bolderText, imageStyle, textStyle } from './styles';
import useGetFontSize from '../../shared/hooks/useGetFontSize';
import aboutUs1 from '../../assets/aboutUs1.png';
import aboutUs2 from '../../assets/aboutUs2.png';
import aboutUs3 from '../../assets/aboutUs3.png';
import aboutUsFarmacon from '../../assets/aboutUsFarmacon.png';

const AboutUs: FC = () => {
  const {
    fontSizeBoldTextAboutUs,
    fontSizeTextAboutUs,
    fontSizeParagraph,
    fontSizeTitleAboutUs,
  } = useGetFontSize();
  const { Text, Title } = Typography;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const collapsedItems: CollapseProps['items'] = [
    {
      key: '1',
      children: (
        <>
          <Row>
            <Text
              strong
              style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}
            >
              Το AGROS ONLINE παρέχει δορυφορικές εικόνες εξαιρετικά υψηλής
              ανάλυσης σε σχεδόν πραγματικό χρόνο, διευκολύνοντας σημαντικά τον
              άμεσο εντοπισμό τυχόν ανωμαλιών στον αγρό. Λειτουργώντας ως
              ολοκληρωμένος σύμβουλος της γεωργίας, το AGROS ONLINE παρέχει
              διορατική καθοδήγηση, ενώ βοηθά σημαντικά στη μείωση των δαπανών
              που σχετίζονται με την άρδευση, την εφαρμογή φυτοφαρμάκων και τη
              λίπανση.
            </Text>
          </Row>
          <Row>
            <Text style={bolderText}>
              Τώρα σας δίνεται η δυνατότητα να γνωρίζετε την ακριβή κατάσταση
              του αγροκτήματός σας χρησιμοποιώντας το AGROS, μια ONLINE λύση που
              σας επιτρέπει να παρακολουθείτε τα αγροτεμάχια σας, καταγράφοντας
              τις καλλιεργητικές φροντίδες, συγκεντρώνοντας όλες τις ενέργειες
              που πραγματοποιούνται στο χωράφι, με δυνατότητα προσθήκης
              φωτογραφιών, παρατηρήσεων και δημιουργία υπενθυμίσεων για τον
              προγραμματισμό εργασιών.
            </Text>
          </Row>
          <Row>
            <Text style={bolderText}>
              Με την τήρηση πλήρους ισοζυγίου θρεπτικών ουσιών αλλά και τον
              Υπολογισμό εφαρμογής λιπασμάτων βάσει των Εδαφολογικών Αναλύσεων
              που θα έχουν καταγραφεί στην εφαρμογή, το AGROS ONLINE, σας βοηθά
              να εξασφαλίζετε τη βέλτιστη θρέψη των καλλιεργειών σας, μειώνοντας
              παράλληλα τις περιττές δαπάνες και τις περιβαλλοντικές επιπτώσεις.
            </Text>
          </Row>
          <Row>
            <Text style={bolderText}>
              Παράλληλα, παρέχονται ενδείξεις όσων αφορά τα νόμιμα όρια και τις
              απαιτήσεις σε θρεπτικά συστατικά, φυτοπροστατευτικά και νερό, με
              εργαλεία παρακολούθησης της κατανάλωσης νερού, αποτρέποντας την
              άσκοπη σπατάλη και αυξάνοντας την αποδοτικότητα της άρδευσης.
            </Text>
          </Row>
        </>
      ),
    },
  ];
  const listItems2 = [
    { key: 1, text: 'Καταγραφή αγροτεμαχίων' },
    {
      key: 2,
      text: 'Πρόγνωση καιρού με βέλτιστους χρόνους ψεκασμού / 48 ωρών',
    },
    { key: 3, text: 'Alert, Ειδοποιήσεις και Υπενθυμίσεις' },
    { key: 4, text: 'Ημερολόγιο εργασιών αγρού' },
    { key: 5, text: 'Πλήρες ισοζύγιο θρεπτικών στοιχείων' },
    { key: 6, text: 'Εφαρμογή λιπασμάτων βάσει Εδαφολογικών Αναλύσεων' },
    { key: 7, text: 'Σωστή εφαρμογή Αρδεύσεων' },
    { key: 8, text: 'Εκτίμηση Ανθρακικού Αποτυπώματος' },
    { key: 9, text: 'Ενδείξεις Νόμιμων ορίων & Απαιτήσεων' },
    { key: 9, text: 'Πρόσβαση στο FARMACON.GR' },
  ];
  const listItems1 = [
    {
      key: 1,
      text: 'Να ελέγχετε την υγεία των καλλιεργειών σας μέσα από δορυφορικές εικόνες υψηλής ανάλυσης και ανίχνευση τυχόν προβλημάτων σε πρώιμο στάδιο.',
    },
    {
      key: 2,
      text: 'Να καταγράφετε όλα τα δεδομένα της παραγωγής σας- από τις εργασίες αγρού έως τη κατανάλωση νερού και λιπασμάτων, εξασφαλίζοντας πλήρη διαφάνεια και οργάνωση.',
    },
    {
      key: 3,
      text: 'Να λαμβάνετε ενημερώσεις σε πραγματικό χρόνο για τις συνθήκες καλλιέργειας, τον καιρό και τις κατάλληλες συνθήκες για ψεκασμούς.',
    },
    {
      key: 4,
      text: 'Να συμβάλλετε στη βιωσιμότητα διαχειριζόμενοι αποτελεσματικά τους πόρους και μειώνοντας το περιβαλλοντικό σας αποτύπωμα.',
    },
  ];
  const items = [
    {
      image: aboutUsFarmacon,
      text: (
        <>
          <Title
            style={{
              ...bolderText,
              fontSize: fontSizeTitleAboutUs,
            }}
          >
            Η ΕΤΑΙΡΙΑ
          </Title>
          <Title style={{ ...textStyle, fontSize: fontSizeParagraph }}>
            FARMACON – An agricultural network
          </Title>
          <Row>
            <Text
              strong
              style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}
            >
              Η Farmacon είναι μια καινοτόμος εταιρία παροχής ψηφιακών υπηρεσιών
              στον τομέα της γεωργικής παραγωγής.
            </Text>
            <Text
              strong
              style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}
            >
              Ιδρύθηκε το 2011 στη Λάρισα, από μια ομάδα έμπειρων γεωπόνων με
              ειδίκευση σε διάφορα επιστημονικά πεδία, με όραμα τις νέες
              τεχνολογίες και σκοπό την ανάπτυξη αξιόπιστων τεχνολογικών λύσεων
              για την βέλτιστη διαχείριση των αγροτικών εκμεταλλεύσεων.
            </Text>
            <Text
              strong
              style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}
            >
              Με βαθιά κατανόηση στις ολοένα αυξανόμενες ανάγκες του πρωτογενή
              τομέα, η τεχνογνωσία της Ομάδας της FARMACON παράλληλα με τη
              διαρκή έρευνα, ανέπτυξε μια σειρά βιώσιμων ψηφιακών λύσεων και
              υπηρεσιών.
            </Text>
            <Text
              strong
              style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}
            >
              Με δέσμευση να ενώσουμε την τεχνολογία με τη γεωργία και
              συνδυάζοντας δορυφορικά και μετεωρολογικά δεδομένα, μοντέλα
              ανάπτυξης καλλιεργειών, ΙοΤ τεχνολογίες και δημιουργώντας
              εξελιγμένα μαθηματικά μοντέλα, η ομάδα μας έχει αναπτύξει μια
              σειρά υπηρεσιών και τεχνολογικών αξιόπιστων λύσεων για την ορθή,
              βιώσιμη και εύκολη διαχείριση της αγροτικής εκμετάλλευσής.
            </Text>
          </Row>
        </>
      ),
    },
    {
      image: aboutUs3,
      text: (
        <Row>
          <Text style={{ ...bolderText, fontSize: fontSizeTitleAboutUs }}>
            Η ΕΦΑΡΜΟΓΗ
          </Text>
          <Title style={{ ...textStyle, fontSize: fontSizeParagraph }}>
            AGROS ONLINE - Ένα νέο digital εργαλείο για τον παραγωγό
          </Title>
          <Text style={{ ...bolderText, fontSize: fontSizeBoldTextAboutUs }}>
            Το απόλυτο εργαλείο για την εύκολη διαχείριση του αγρού.
          </Text>
          <Text strong style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}>
            Η AGROS ONLINE είναι η καινοτόμα εφαρμογή που αναπτύχθηκε από τη
            FARMACON με στόχο να μετασχηματίσει την παρακολούθηση των
            καλλιεργειών και τις διαδικασίες λήψης γεωργικών αποφάσεων.
            Σχεδιασμένη ειδικά για να καλύπτει τις ανάγκες του σύγχρονου αγρότη
            και γεωπόνου, η AGROS ONLINE συνδυάζει την απλότητα χρήσης με την
            ισχύ της γεωργίας ακριβείας.
          </Text>
          <Text strong style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}>
            Ένα εύκολο στη χρήση ολοκληρωμένο σύστημα, που συνδυάζει τα δεδομένα
            της εκμετάλλευσης, αξιοποιεί την τεχνολογία δορυφορικής
            τηλεπισκόπησης για να διευκολύνει την παρακολούθηση του αγρού σε
            σχεδόν πραγματικό χρόνο, ενώ παράλληλα διατηρεί τα δεδομένα για
            εύκολη παρακολούθηση και κοινή χρήση.
          </Text>
          <Text strong style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}>
            Η AGROS ONLINE παρέχει δορυφορικές εικόνες υψηλής ανάλυσης για τον
            άμεσο εντοπισμό των προβλημάτων, καταγραφή καλλιεργητικών φροντίδων
            για βελτιστοποίηση θρέψης, άρδευσης και φυτοπροστασίας. Με εργαλεία
            διαχείρισης πόρων και υπενθυμίσεις, βοηθά στη μείωση των εξόδων και
            ενισχύει τη παραγωγικότητα με σεβασμό στο περιβάλλον.
          </Text>
          <>
            <Title style={{ ...bolderText, fontSize: fontSizeBoldTextAboutUs }}>
              Αυξήστε την παραγωγικότητά σας, εξοικονομήστε χρόνο και κερδίστε
              αποδοτικότητα!!
            </Title>
            <Text
              strong
              style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}
            >
              Αποκτήστε πρόσβαση σε εξειδικευμένες γεωπονικές συστάσεις και
              εργαλεία που καλύπτουν κάθε πτυχή της αγροτικής παραγωγής.
              Παρακολουθήστε την άρδευση, την έκπλυση θρεπτικών στοιχείων, την
              αλατότητα και τη θερμοκρασία του εδάφους, εντοπίστε κινδύνους
              μυκητολογικών ασθενειών και βελτιστοποιήστε εφαρμογές ψεκασμού.
              Διαχειριστείτε την πρόοδο της καλλιέργειας και τη χωρική
              μεταβλητότητα, αξιοποιήστε δεδομένα βροχόπτωσης και
              εξατμισοδιαπνοής (ET), καθώς και ακριβείς καιρικές προβλέψεις.
            </Text>
            <Collapse
              ghost
              items={collapsedItems}
              expandIcon={({ isActive }) => (
                <Space size={5}>
                  <RightOutlined
                    rotate={isActive ? 90 : 0}
                    style={{ color: 'white' }}
                  />
                  <Text style={{ fontSize: 15, color: 'white' }}>
                    {isActive ? 'Λιγότερα...' : 'Περισσότερα...'}
                  </Text>
                </Space>
              )}
            />
          </>
        </Row>
      ),
    },
    {
      image: aboutUs1,
      text: (
        <>
          <Title style={{ ...textStyle, fontSize: fontSizeParagraph }}>
            Με την AGROS ONLINE, έχετε τη δυνατότητα
          </Title>
          <Text strong style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}>
            <ul>
              {listItems1.map((item) => (
                <li key={item.key}>{item.text}</li>
              ))}
            </ul>
          </Text>
        </>
      ),
    },
    {
      image: aboutUs2,
      text: (
        <>
          <Title style={{ ...textStyle, fontSize: fontSizeParagraph }}>
            Η νέα ψηφιακή πλατφόρμα παρέχει στους αγρότες εύκολη πρόσβαση σε
            πληροφορίες σχεδόν σε πραγματικό χρόνο για την αύξηση της
            αποτελεσματικότητας των εργασιών τους.
          </Title>
          <Text strong style={{ ...textStyle, fontSize: fontSizeTextAboutUs }}>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {listItems2.map((item) => (
                <div key={item.key}>
                  <li> ✓ {item.text}</li>
                </div>
              ))}
              Η νέα ψηφιακή εμπειρία για τους παραγωγούς powered by Farmacon.
            </ul>
          </Text>
        </>
      ),
    },
    {
      image: '',
      text: (
        <>
          <Text
            strong
            style={{ ...textStyle, fontSize: fontSizeBoldTextAboutUs }}
          >
            Η AGROS ONLINE λειτουργεί ως ένας ψηφιακός συνεργάτης που απλοποιεί
            τη καθημερινότητα και ενισχύει την αποτελεσματικότητα κάθε
            παραγωγού, γεωπόνου ή συμβούλου καλλιέργειας. Με τη δυνατότητα
            πολλαπλών χρηστών και πλήρως προσαρμόσιμη στις ανάγκες κάθε
            καλλιέργειας, η εφαρμογή ανοίγει νέους δρόμους στη γεωργία
            ακριβείας.
          </Text>
          <Title style={{ ...bolderText, fontSize: fontSizeParagraph }} italic>
            Ανακαλύψτε τη δύναμη της ψηφιακής γεωργίας, αποκτήστε πρόσβαση σε
            δεδομένα και υπηρεσίες αιχμής και προχωρήστε με σιγουριά προς το
            μέλλον της αγροτικής παραγωγής με την AGROS ONLINE, το συνεργάτη σας
            στην επιτυχία.
          </Title>
        </>
      ),
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <>
          <Row
            key={index}
            justify="center"
            align="middle"
            gutter={[50, 15]}
            style={index == 0 ? { marginTop: 90 } : {}}
          >
            {(screens.md === true ||
              screens.lg === true ||
              screens.xl === true ||
              screens.xxl === true) &&
            index % 2 === 0 ? (
              <>
                <Col xs={23} sm={23} md={item.image !== '' ? 11 : 23}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={rightTransition}
                    viewport={{ once: false, amount: 0.5 }}
                  >
                    {item?.text}
                  </motion.div>
                </Col>
                {item?.image && (
                  <Col xs={22} md={item.image !== '' ? 10 : 23}>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      variants={leftTransition}
                      viewport={{ once: false, amount: 0.5 }}
                    >
                      <Image
                        loading="lazy"
                        width="100%"
                        preview={false}
                        height="auto"
                        src={item.image}
                        style={imageStyle}
                      />
                    </motion.div>
                  </Col>
                )}
              </>
            ) : (
              <>
                {item.image && (
                  <Col xs={22} sm={22} md={item.image !== '' ? 10 : 23}>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      variants={rightTransition}
                      viewport={{ once: false, amount: 0.5 }}
                    >
                      <Image
                        loading="lazy"
                        width="100%"
                        preview={false}
                        src={item.image}
                        style={imageStyle}
                      />
                    </motion.div>
                  </Col>
                )}
                <Col xs={22} sm={22} md={item.image !== '' ? 12 : 23}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={leftTransition}
                    viewport={{ once: false, amount: 0.5 }}
                  >
                    {item.text}
                  </motion.div>
                </Col>
              </>
            )}
          </Row>
          <Divider />
        </>
      ))}
    </>
  );
};
export default AboutUs;
