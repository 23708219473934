import { FC, ReactNode } from 'react';
import { Layout, Col } from 'antd';
import { baseStyle } from '../../shared/styles/baseStyle';
import { layOutCol } from './styles';
import Header from './Layout/Header';
import introBackground from '../../assets/introBackground.jpg';
type Props = {
  children: ReactNode;
};
const InfoMenuLayout: FC<Props> = ({ children }) => {
  const { Content } = Layout;
  return (
    <Layout style={{ ...baseStyle.layoutStyle }}>
      <Col
        style={{
          ...layOutCol,
          backgroundImage: `url(${introBackground})`,
        }}
      >
        <Header />
        <Content style={{ overflow: 'hidden' }}>{children}</Content>
      </Col>
    </Layout>
  );
};

export default InfoMenuLayout;
