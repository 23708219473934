import { FC } from 'react';
import { Layout, Col } from 'antd';
import Header from './Header';
import { baseStyle } from '../../../shared/styles/baseStyle';
import { layOutCol, featuresContent } from '../styles';
import Footer from './Footer';
import introBackground from '../../../assets/introBackground.jpg';
import Intro from '../Intro';
import Features from '../Features';
import './Info.css';

const InfoLayout: FC = () => {
  const { Content } = Layout;
  return (
    <Layout style={{ ...baseStyle.layoutStyle }}>
      <Col
        style={{
          ...layOutCol,
          backgroundImage: `url(${introBackground})`,
        }}
      >
        <Header />
        <Content style={{ overflow: 'hidden' }}>
          <Intro />
        </Content>
      </Col>
      <Content style={{ ...featuresContent, overflowX: 'hidden' }}>
        <Features />
      </Content>
      {/* <Footer /> */}
    </Layout>
  );
};

export default InfoLayout;
