import { FC, lazy } from 'react';
import { Col, Row, Typography, Image } from 'antd';
import hand from '../../assets/hand.png';
import { motion } from 'framer-motion';
import { upTransition, downTransition, handImage } from './styles';
import { baseColors } from '../../shared/styles/baseStyle';
import useGetFontSize from '../../shared/hooks/useGetFontSize';

const Intro: FC = () => {
  const { Title, Paragraph } = Typography;
  const { fontSizeTitle, fontSizeParagraph } = useGetFontSize();
  return (
    <Row
      style={{
        position: 'relative',
      }}
      justify="center"
    >
      <Col
        xs={{ span: 23 }}
        sm={{ span: 20 }}
        md={{ span: 13, push: 1 }}
        lg={{ span: 12, push: 1 }}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={downTransition}
          viewport={{ once: true, amount: 0.2 }}
        >
          <Typography>
            <Row gutter={[0, 15]}>
              <Title
                style={{
                  color: 'white',
                  fontSize: fontSizeTitle,
                  fontWeight: 'bold',
                }}
              >
                Ο εύκολος τρόπος να έχεις τον πλήρη έλεγχο του{' '}
                <span style={{ color: baseColors.fsBlue }}>Αγρού</span> σου!
              </Title>
              <Paragraph
                strong
                style={{
                  fontSize: fontSizeParagraph,
                  color: 'white',
                }}
              >
                Το πιο αξιόπιστο και ολοκληρωμένο ευφυές σύστημα αγροτικών
                υπηρεσιών για την συνεχή και έγκαιρη παρακολούθηση των
                περιβαλλοντικών συνθηκών και της καλλιέργειας, με ακριβή
                δεδομένα πολλαπλών πηγών.
              </Paragraph>
            </Row>
          </Typography>
        </motion.div>
      </Col>
      <Col xs={24} sm={24} md={11} lg={12} style={handImage}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={upTransition}
          viewport={{ once: false, amount: 0.1 }}
          style={{
            display: 'inline-block',
            width: '66%',
          }}
        >
          <Image
            preview={false}
            height="auto"
            width="100%"
            src={hand}
            loading="lazy"
          />
        </motion.div>
      </Col>
    </Row>
  );
};

export default Intro;
