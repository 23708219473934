import { Grid } from 'antd';

const useGetFontSize = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const fontSizeTitle = screens.xxl
    ? 60
    : screens.xl
      ? 52
      : screens.lg
        ? 52
        : screens.md
          ? 43
          : screens.sm
            ? 35
            : 35;

  const fontSizeParagraph = screens.xxl
    ? 32
    : screens.xl
      ? 28
      : screens.lg
        ? 22
        : screens.md
          ? 22
          : 18;
  const fontSizeText = screens.xxl
    ? 22
    : screens.xl
      ? 18
      : screens.lg
        ? 15
        : screens.md
          ? 15
          : screens.sm
            ? 16
            : 14;

  const fontSizeTextAboutUs = screens.xxl
    ? 23
    : screens.xl
      ? 17
      : screens.lg
        ? 17
        : screens.md
          ? 15
          : screens.sm
            ? 15
            : 14;
  const fontSizeTitleAboutUs = screens.xxl
    ? 52
    : screens.xl
      ? 40
      : screens.lg
        ? 35
        : screens.md
          ? 35
          : screens.sm
            ? 30
            : 30;

  const fontSizeBoldTextAboutUs = screens.xxl
    ? 24
    : screens.xl
      ? 20
      : screens.lg
        ? 21
        : screens.md
          ? 17
          : screens.sm
            ? 17
            : 16;
  return {
    fontSizeText,
    fontSizeTitle,
    fontSizeParagraph,
    fontSizeBoldTextAboutUs,
    fontSizeTextAboutUs,
    fontSizeTitleAboutUs,
  };
};
export default useGetFontSize;
