import { FC, useContext } from 'react';
import { Col, Dropdown, Flex, Layout, MenuProps, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../../shared/context/auth-context';
import { useNavigate } from 'react-router-dom';
import NotificationList from '../EventReminder/NotificationList';
const Header: FC = () => {
  const navigate = useNavigate();
  const { Header } = Layout;
  const { logout, firstName, lastName, role } = useContext(AuthContext);

  const items: MenuProps['items'] = [
    ...(role !== 'Διαχειριστής'
      ? [
          {
            label: 'Το πακέτο μου',
            key: '/mysubscription',
          },
        ]
      : []),
    {
      label: 'Αλλαγή στοιχείων πρόσβασης',
      key: '/profile',
    },
    { type: 'divider' },
    {
      danger: true,
      label: 'Αποσύνδεση',
      key: '/logout',
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '/logout') {
      logout();
      return;
    }
    navigate(e.key);
  };

  return (
    <Header style={{}}>
      <Row
        justify="end"
        style={{
          width: '100%',
          height: '100%',
        }}
        align="bottom"
      >
        <Col sm={{ span: 4 }}>
          <Flex justify="flex-end" align="center" gap={15}>
            <NotificationList />
            <Dropdown.Button
              icon={<UserOutlined />}
              size="large"
              type="primary"
              menu={{
                items: items,
                onClick: handleMenuClick,
              }}
              trigger={['click']}
            >
              {firstName} {lastName}
            </Dropdown.Button>
          </Flex>
        </Col>
      </Row>
    </Header>
  );
};
export default Header;
