import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { mutateData } from '../services/apiServices';
import endPoints from '../endPoints.json';
import useCreateAxiosInstance from './useCreateAxiosInstance';
import { useContext } from 'react';
import { EventReminders } from '../context/eventReminder';
import { CultivationActivity } from '../context/activityMutation';

const useReminderMutation = () => {
  const { setReminderMutation, reminderMutation } = useContext(EventReminders);
  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const { request } = useCreateAxiosInstance();
  const { message } = App.useApp();
  return useMutation({
    mutationFn: (values: any) =>
      mutateData(
        request,
        endPoints.EVENTREMINDER.EVENTREMINDER,
        values,
        'post',
      ),
    onSuccess: () => {
      message.success('Η υπενθύμιση προστέθηκε με επιτυχία!');
      setReminderMutation(!reminderMutation);
      setActivityMutation(!activityMutation);
    },
  });
};

export default useReminderMutation;
