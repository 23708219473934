import { FC, createContext, useCallback, useMemo, useState } from 'react';
export const EventReminders = createContext<any>({
  reminderMutation: false,
  setReminderMutation: () => {
    // intentionally empty
  },
});
type Props = {
  children: any;
};
const EventReminderProvider: FC<Props> = ({ children }) => {
  const [reminderMutation, setReminderMutation] = useState(false);
  const memoedValue = useMemo(
    () => ({
      reminderMutation,
      setReminderMutation,
    }),
    [reminderMutation, setReminderMutation],
  );
  return (
    <EventReminders.Provider value={memoedValue}>
      {children}
    </EventReminders.Provider>
  );
};
export default EventReminderProvider;
