import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  App,
} from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import {
  CalendarEventContent,
  LandParcel,
  VisitEvent,
} from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import TextArea from 'antd/es/input/TextArea';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mutateData, getData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import { CultivationActivity } from '../../../shared/context/activityMutation';
import endPoints from '../../../shared/endPoints.json';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Method } from 'axios';
import CommonFormItems from './CommonFormItems';
import EventReminder from '../../EventReminder/EventReminder';
import useReminderMutation from '../../../shared/hooks/useEventReminderMutation';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';

type Props = {
  selectedCultivation: LandParcel | undefined;

  selectedDate: Dayjs | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
  viewOnly: boolean;
};
const VisitsForm: FC<Props> = ({
  selectedCultivation,
  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
  viewOnly,
}) => {
  const { message } = App.useApp();
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };

  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const { mutate: mutateReminder } = useReminderMutation();
  const { data: visitsData, isLoading } = useQuery({
    queryKey: ['visitsData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELVISIT}/${selectedEvent?.id}`,
      ),
    select(data: { data: VisitEvent }) {
      return data.data;
    },
    enabled: !!selectedEvent,
  });

  useEffect(() => {
    if (visitsData) {
      form.setFieldsValue({
        cultivation: !selectedEvent?.reminderId
          ? selectedEvent?.landParcelCultivation
          : selectedEvent?.cultivationName,
        landParcelId: selectedEvent?.landParcelId,
        visitDate: !selectedEvent?.reminderId
          ? dayjs(visitsData.visitDate)
          : undefined,
        visitReason: visitsData.visitReason,
        observations: visitsData.observations,
      });
    }
  }, [visitsData]);
  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        visitDate: selectedDate?.startOf('day'),
      });
    }
  }, [selectedCultivation]);
  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: VisitEvent & { reminderDate: number }) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELVISIT,
        formValues,
        apiMethod,
      ),
    onSuccess(data, variables) {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
      variables?.reminderDate &&
        mutateReminder({
          eventId: data.data,
          eventType: endPoints.CALENDAR.LANDPARCELVISIT,
          reminderDate: variables.reminderDate,
        });
    },
  });
  if (isLoading || isPending) return <LoadingSpin />;
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      visitDate: dayjs(values.visitDate?.$d).startOf('day').valueOf(),
      id: selectedEvent?.id,
      reminderDate: values?.reminderDate?.$d.getTime(),
    };
    mutate(formattedValues);
  };
  return (
    <>
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <CommonFormItems />
        <Row>
          <Col xs={12}>
            <Form.Item
              name="visitDate"
              label="Ημ/νια Επίσκεψης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια Επίσκεψης"
                style={fullWidth}
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="visitReason"
              label="Λόγος Επίσκεψης - Ελέγχου"
              rules={[requiredRuleConfig]}
            >
              <Input
                placeholder="Λόγος Επίσκεψης - Ελέγχου"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              name="observations"
              label="Παρατηρήσεις - Συστάσεις"
              rules={[requiredRuleConfig]}
            >
              <TextArea
                rows={3}
                placeholder="Παρατηρήσεις - Συστάσεις"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        {!viewOnly && (
          <EventReminder
            reminderDate={apiMethod == 'put' ? visitsData?.reminderDate : null}
          />
        )}
        {apiMethod == 'post' && (
          <Row justify="center">
            <Button htmlType="submit">Προσθήκη</Button>
          </Row>
        )}
        {!viewOnly && apiMethod == 'put' && (
          <Row justify="center">
            <Button htmlType="submit">Αποθήκευση</Button>
          </Row>
        )}
      </Form>
    </>
  );
};
export default VisitsForm;
