import { FC, createContext, useMemo, useState } from 'react';
export const CultivationActivity = createContext<any>({
  setActivityMutation: () => {
    // intentionally empty
  },
  activityMutation: false,
});
type Props = {
  children: any;
};
const CultivationActivityProvider: FC<Props> = ({ children }) => {
  const [activityMutation, setActivityMutation] = useState(false);
  const memoedValue = useMemo(
    () => ({
      activityMutation,
      setActivityMutation,
    }),
    [activityMutation, setActivityMutation],
  );
  return (
    <CultivationActivity.Provider value={memoedValue}>
      {children}
    </CultivationActivity.Provider>
  );
};
export default CultivationActivityProvider;
