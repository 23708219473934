import { FC, useContext, useEffect, useMemo, useState } from 'react';
import type { CalendarProps } from 'antd';
import {
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Flex,
  Grid,
  Modal,
  Row,
  Tag,
  Typography,
} from 'antd';
import type { Dayjs, OpUnitType } from 'dayjs';
import dayjs from 'dayjs';
import endPoints from '../../shared/endPoints.json';
import { useQuery } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { getData } from '../../shared/services/apiServices';
import CalendarList from './CalendarList';
import { addCatalogueButton, cardStyle } from '../../shared/styles/baseStyle';
import { CalendarEventContent, CalendarEvent } from '../../types/types';
import { AppstoreAddOutlined, UndoOutlined } from '@ant-design/icons';
import AddEvent from './AddEvent';
import { scrollableDiv } from './styles';
import { CultivationActivity } from '../../shared/context/activityMutation';
const getLastDay = (date: Dayjs, panel: OpUnitType) => {
  return date.endOf(panel).startOf('day').valueOf();
};
const getFirstDay = (date: Dayjs, panel: OpUnitType) => {
  return date.startOf(panel).valueOf();
};
const tagsData = [
  { name: 'Λίπανση', color: '#FFA500' },
  { name: 'Φυτοπροστασία', color: '#A020F0' },
  { name: 'Άρδευση', color: '#0AAEF0' },
  { name: 'Καλλιεργητικές φροντίδες', color: '#964B00' },
  { name: 'Ποιοτικά χαρακτηριστικά', color: '#008000' },
  { name: 'Συγκομιδή', color: '#E9B40B' },
  { name: 'Επισκέψεις', color: '#FF0000' },
  { name: 'Clear All', color: 'black' },
];

type Props = {
  defaultDate: Dayjs | undefined;
  calendarSpan: number;
};
const FarmerCalendar: FC<Props> = ({ defaultDate, calendarSpan }) => {
  type ExtendedCalendarData = CalendarEventContent & { dateTime: number };
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(
    defaultDate,
  );
  const [startDate, setStartDate] = useState(getFirstDay(dayjs(), 'month'));
  const [endDate, setEndDate] = useState(getLastDay(dayjs(), 'month'));
  const [panel, setPanel] = useState('month');
  const { request } = useCreateAxiosInstance();
  const [eventList, setEventList] = useState<CalendarEvent[] | []>();
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const { data: calendarData, isSuccess } = useQuery({
    queryKey: ['calendarData', startDate, activityMutation],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.CALENDAR}?DateFrom=${startDate}&DateTo=${endDate}`,
      ),
    select(data: { data: CalendarEvent[] }) {
      return data.data;
    },
  });
  const filterCalendarDataByTags = (data: CalendarEvent[] | undefined) => {
    if (!data) return [];
    if (selectedTags.includes('Clear All')) {
      setSelectedTags([]);
      return data;
    }
    if (selectedTags.length == 0) return data;
    return data
      .map((item) => ({
        ...item,
        contents: item.contents.filter((content) =>
          selectedTags.includes(content.nameGR),
        ),
      }))
      .filter((item) => item.contents.length > 0);
  };
  const handleOnSelect = (selectedDate: Dayjs | undefined, source: string) => {
    if (source === 'date' || defaultDate) {
      setSelectedDate(selectedDate);
      return;
    }
    if (source === 'month') {
      setSelectedDate(undefined);
    }
  };

  const getFilteredEventList = (date?: Dayjs) => {
    if (!date) {
      return filterCalendarDataByTags(calendarData);
    }
    return filterCalendarDataByTags(
      calendarData?.filter(
        (item) => item.dateTime === dayjs(date).startOf('day').valueOf(),
      ),
    );
  };
  useEffect(() => {
    if (calendarData && isSuccess) {
      setEventList(getFilteredEventList(selectedDate));
    }
  }, [calendarData, selectedTags, selectedDate]);

  const filteredData = useMemo(() => {
    if (!calendarData || !isSuccess) return [];
    return filterCalendarDataByTags(calendarData);
  }, [calendarData, selectedTags, isSuccess]);

  const groupedDataByMonth = useMemo(() => {
    return filteredData.reduce(
      (acc, item) => {
        const monthKey = dayjs(item.dateTime).startOf('month').valueOf();
        acc[monthKey] = acc[monthKey] || [];
        acc[monthKey].push(
          ...item.contents.map((content) => ({
            ...content,
            dateTime: item.dateTime,
          })),
        );
        return acc;
      },
      {} as Record<string, ExtendedCalendarData[]>,
    );
  }, [filteredData]);
  const getMonthData = (value: Dayjs): ExtendedCalendarData[] => {
    const monthKey = value.startOf('month').valueOf();
    return groupedDataByMonth[monthKey] || [];
  };
  const monthCellRender = (value: Dayjs) => {
    const monthData = getMonthData(value);
    return (
      monthData.length > 0 && (
        <>
          {monthData.map((item, index) => (
            <li key={index}>
              <Badge
                key={index}
                color={item.color}
                text={
                  <>
                    <Typography.Text strong>
                      {item.landParcelName}
                      {', '}
                    </Typography.Text>
                    <Typography.Text>
                      {dayjs(item.dateTime).format('DD/MM')}
                    </Typography.Text>
                  </>
                }
              />
            </li>
          ))}
        </>
      )
    );
  };
  const getCalendarItems = (epoch: number): CalendarEventContent[] => {
    const data = filterCalendarDataByTags(calendarData);
    return (
      data?.find((item: CalendarEvent) => item.dateTime === epoch)?.contents ||
      []
    );
  };

  const dateCellRender = (value: Dayjs) => {
    const calendarItems = getCalendarItems(value.startOf('day').valueOf());
    const rows: [CalendarEventContent[]] = [[]];
    for (let i = 0; i < calendarItems.length; i += 4) {
      rows.push(calendarItems.slice(i, i + 4));
    }
    const badgeRows = Array.from(
      { length: Math.ceil(calendarItems.length / 4) },
      (_, i) => calendarItems.slice(i * 4, (i + 1) * 4),
    );
    return (
      <>
        {badgeRows.length > 0 &&
          badgeRows.map((row, rowIndex) => (
            <Row key={rowIndex} justify="center">
              <Flex vertical={false} gap={1} key={rowIndex}>
                {row.map((item, index) => (
                  <Badge key={index} color={item?.color} />
                ))}
              </Flex>
            </Row>
          ))}
      </>
    );
  };
  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };
  const handleTagChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };
  const hexToRgba = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const renderCalendarList = () => (
    <CalendarList
      eventList={eventList}
      selectedDate={selectedDate}
      month={dayjs(startDate).format('MMMM')}
    />
  );
  return (
    <Row justify="center">
      <Col xs={24}>
        <Flex gap="small" vertical>
          {!defaultDate && (
            <Button
              onClick={() => {
                setIsModalOpen(true);
              }}
              style={addCatalogueButton}
              type="primary"
              size="large"
              icon={<AppstoreAddOutlined />}
            >
              Προσθήκη
            </Button>
          )}
          <Row>
            <Flex>
              <Col span={24}>
                <span>Ενέργειες: </span>
                {tagsData.map((tag) => (
                  <Tag.CheckableTag
                    key={tag.name}
                    style={{
                      color: tag.color,
                      backgroundColor: selectedTags.includes(tag.name)
                        ? `${hexToRgba(tag.color, 0.1)}`
                        : 'transparent',
                    }}
                    checked={selectedTags.includes(tag.name)}
                    onChange={(checked) => handleTagChange(tag.name, checked)}
                  >
                    {tag.name === 'Clear All' ? (
                      <UndoOutlined style={{ fontSize: '20px' }} />
                    ) : (
                      tag.name
                    )}
                  </Tag.CheckableTag>
                ))}
              </Col>
            </Flex>
          </Row>
          <Modal
            open={isModalOpen}
            footer={false}
            width={800}
            destroyOnClose
            onCancel={() => {
              setIsModalOpen(false);
            }}
          >
            <AddEvent
              selectedDate={selectedDate}
              setIsModalOpen={setIsModalOpen}
              setActivityMutation={setActivityMutation}
              activityMutation={activityMutation}
            />
          </Modal>
          <Row align="top" justify="start">
            <Col
              xs={24}
              md={panel == 'year' ? 23 : calendarSpan}
              xl={panel == 'year' ? 23 : calendarSpan + 1}
            >
              <Card style={cardStyle}>
                <Calendar
                  cellRender={cellRender}
                  onSelect={(date, info) => {
                    handleOnSelect(date, info.source);
                  }}
                  onPanelChange={(date, info) => {
                    setStartDate(getFirstDay(date, info));
                    setEndDate(getLastDay(date, info));
                    setPanel(info);
                  }}
                />
              </Card>
            </Col>
            {panel == 'month' && (
              <Col
                xs={24}
                sm={20}
                md={24 - calendarSpan}
                xl={23 - calendarSpan}
              >
                {breakpoints.xs ? (
                  renderCalendarList()
                ) : (
                  <div
                    id="scrollableDiv"
                    style={{ ...scrollableDiv, scrollbarWidth: 'thin' }}
                  >
                    {renderCalendarList()}
                  </div>
                )}
              </Col>
            )}
          </Row>
        </Flex>
      </Col>
    </Row>
  );
};
export default FarmerCalendar;
