import { Col, Collapse, DatePicker, Form, Row, Typography } from 'antd';
import { FC } from 'react';
import dayjs from 'dayjs';

type Props = {
  reminderDate: number | null | undefined;
};
const EventReminder: FC<Props> = ({ reminderDate }) => {
  return (
    <Row>
      <Col span={24}>
        <Collapse
          ghost
          defaultActiveKey={reminderDate ? ['1'] : []}
          items={[
            {
              key: '1',
              label: 'Υπενθύμιση εργασίας',
              children: (
                <>
                  <Row justify="start" align="middle">
                    <Col span={24}>
                      {reminderDate ? (
                        <>
                          <Row>
                            <Col span={14}>
                              Το Σύστημα έχει δημιουργήσει μία υπενθύμιση για
                              την ενέργεια αυτή στις:{' '}
                              <Typography.Text strong>
                                {` ${dayjs(reminderDate).format('DD/MM/YYYY')}`}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Form.Item
                          name="reminderDate"
                          label={
                            <Row>
                              <Col span={14}>
                                Το Σύστημα θα δημιουργήσει μία υπενθύμιση για
                                την ενέργεια αυτή στις:
                              </Col>
                            </Row>
                          }
                        >
                          <DatePicker style={{ width: '50%' }} allowClear />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </>
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};
export default EventReminder;
