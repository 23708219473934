import {
  Button,
  Card,
  Divider,
  List,
  Skeleton,
  Space,
  Typography,
  Row,
  Popover,
  Badge,
  Modal,
} from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { scrollableDiv } from './styles';
import { BellFilled } from '@ant-design/icons';
import { baseColors } from '../../shared/styles/baseStyle';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { CalendarEventContent, EventReminder } from '../../types/types';
import { EventReminders } from '../../shared/context/eventReminder';
import FertilizerForm from '../Forms/Calendar/FertilizerForm';
import IrrigationForm from '../Forms/Calendar/IrrigationForm';
import CultivationCareForm from '../Forms/Calendar/CultivationCareForm';
import HarvestForm from '../Forms/Calendar/HarvestForm';
import PlantProtectionForm from '../Forms/Calendar/PlantProtectionForm';
import VisitsForm from '../Forms/Calendar/VisitsForm';
import QualityCharacteristicsForm from '../Forms/Calendar/QualityCharacteristicsForm';
import { Method } from 'axios';

const NotificationList: FC = () => {
  const { reminderMutation } = useContext(EventReminders);
  const [notifications, setNotifications] = useState<EventReminder[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 8;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const { request } = useCreateAxiosInstance();
  const loadMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const { data: eventReminders, isSuccess } = useQuery({
    queryKey: ['eventReminders', page, reminderMutation],
    queryFn: () =>
      getData(
        request,
        `${endPoints.EVENTREMINDER.EVENTREMINDER}?Items=${pageSize}&Page=${page}`,
      ),
    select(data) {
      return data.data;
    },
  });
  useEffect(() => {
    if (eventReminders && isSuccess) {
      if (page == 1) {
        setNotifications(eventReminders.items);
      } else {
        setNotifications([...notifications, ...eventReminders.items]);
      }
      setCount(eventReminders?.totalCount);
    }
  }, [eventReminders, isSuccess]);

  const commonProps = (): {
    selectedCultivation: undefined;
    selectedDate: undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedEvent: CalendarEventContent | undefined;
    successMessage: string;
    apiMethod: Method;
    viewOnly: boolean;
  } => {
    return {
      setIsModalOpen,
      selectedCultivation: undefined,
      selectedDate: undefined,
      selectedEvent,
      apiMethod: 'post',
      successMessage: 'Η ενέργεια δημιουργήθηκε με επιτυχία!',
      viewOnly: false,
    };
  };
  const calendarActivityForms: { [key: string]: JSX.Element } = {
    Λίπανση: <FertilizerForm {...commonProps()} />,
    Καλλιεργητικέςφροντίδες: <CultivationCareForm {...commonProps()} />,
    Άρδευση: <IrrigationForm {...commonProps()} />,
    Συγκομιδή: <HarvestForm {...commonProps()} />,
    Φυτοπροστασία: <PlantProtectionForm {...commonProps()} />,
    Επισκέψεις: <VisitsForm {...commonProps()} />,
    Ποιοτικάχαρακτηριστικά: <QualityCharacteristicsForm {...commonProps()} />,
  };
  return (
    <Popover
      content={
        <div id="scrollableDiv" style={scrollableDiv}>
          <InfiniteScroll
            dataLength={notifications.length}
            next={loadMoreData}
            height={450}
            hasMore={notifications.length < count}
            loader={<Skeleton paragraph={{ rows: 1 }} />}
            endMessage={
              notifications.length > 0 && (
                <>
                  <Divider />
                  <Row justify="center">
                    Αυτές είναι όλες οι υπενθυμίσεις των τελευταίων 30 ημερών!
                  </Row>
                </>
              )
            }
            scrollableTarget="scrollableDiv"
          >
            <Card title="Υπενθυμίσεις">
              <List
                dataSource={notifications}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button
                        key={index}
                        size="small"
                        onClick={() => {
                          setPage(1);
                          setIsModalOpen(true);
                          setSelectedEvent({
                            ...item,
                            id: item.eventId,
                            reminderId: item.id,
                          });
                        }}
                      >
                        Προσθήκη
                      </Button>,
                    ]}
                  >
                    <Space direction="vertical">
                      <Typography.Text strong>{item.eventName}</Typography.Text>
                      <Typography.Text type="secondary">
                        {`Αγροτεμάχιο: ${item.landParcelName}`}
                      </Typography.Text>
                      <Typography.Text>
                        {`Ημ/νία Υπενθύμισης: ${dayjs(item.reminderDate).format('DD/MM/YYYY')}`}
                      </Typography.Text>
                    </Space>
                  </List.Item>
                )}
              />
              <Modal
                open={isModalOpen}
                footer={false}
                width={800}
                destroyOnClose
                onCancel={() => {
                  setIsModalOpen(false);
                }}
              >
                {selectedEvent &&
                  calendarActivityForms[
                    selectedEvent.eventName.replace(/\s+/g, '')
                  ]}
              </Modal>
            </Card>
          </InfiniteScroll>
        </div>
      }
      trigger="click"
    >
      <Badge count={count} size="small" offset={[0, 5]}>
        <BellFilled
          style={{
            fontSize: '27px',
            color: baseColors.fsBlue,
          }}
          rotate={25}
          onClick={() => {
            setPage(1);
          }}
        />
      </Badge>
    </Popover>
  );
};
export default NotificationList;
