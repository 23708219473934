import { FC } from 'react';
import { Col, Form, Input, Row } from 'antd';

const CommonFormItems: FC = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.Item name="landParcelId" label="Κωδικός Αγροτεμαχίου">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item name="cultivation" label="Καλλιέργεια">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default CommonFormItems;
