import { Col, Row, Card, Typography, List, Flex, Divider } from 'antd';
import {
  baseColors,
  baseStyle,
  cardStyle,
} from '../../shared/styles/baseStyle';
import { FC, useContext } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import {
  actionCardCol,
  subscriptionCardBody,
  subscriptionCardHeader,
  titleCardCol,
} from './style';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../shared/context/auth-context';

const Subscriptions: FC = () => {
  const { subscription: accountSubscription } = useContext(AuthContext);
  const navigate = useNavigate();
  const { Text } = Typography;
  const basicTitle = 'Basic';
  const premiumTitle = 'Premium';
  const basicDescription = 'Αποκλειστικά μέσω ΟΠΕΚΕΠΕ';
  const premiumDescription = 'Ιδανική για Αγρότες & Γεωπόνους';
  const basicheaderTitle = 'Εφαρμογή ΟΠΕΚΕΠΕ';
  const premiumHeaderTitle = 'SMART FARMING';
  const contactUs = 'Επικοινωνήστε μαζί μας';
  const featuresBasic = [
    'ΚΑΤΑΓΡΑΦΗ ΑΓΡΟΤΕΜΑΧΙΩΝ',
    'ΗΜΕΡΟΛΟΓΙΟ ΕΡΓΑΣΙΩΝ ΑΓΡΟΥ',
    'ΣΥΜΒΟΥΛΕΣ ΘΡΕΨΗΣ & ΠΛΗΡΕΣ ΙΣΟΖΥΓΙΟ ΘΡΕΠΤΙΚΩΝ ΟΥΣΙΩΝ',
    'ΥΠΟΛΟΓΙΣΜΟΣ ΕΦΑΡΜΟΓΗΣ ΛΙΠΑΣΜΑΤΩΝ ΒΑΣΕΙ ΕΔΑΦΟΛΟΓΙΚΩΝ ΑΝΑΛΥΣΕΩΝ',
    'ΕΝΔΕΙΞΕΙΣ ΟΣΩΝ ΑΦΟΡΑ ΤΑ ΝΟΜΙΜΑ ΟΡΙΑ ΚΑΙ ΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΣΕ ΘΡΕΠΤΙΚΑ ΣΥΣΤΑΤΙΚΑ, ΦΥΤΟΠΡΟΣΤΑΤΕΥΤΙΚΑ ΚΑΙ ΝΕΡΟ',
    'ΕΚΤΙΜΗΣΗ ΑΝΘΡΑΚΙΚΟΥ ΑΠΟΤΥΠΩΜΑΤΟΣ',
    'ΚΑΤΑΝΑΛΩΣΗ ΥΔΑΤΟΣ',
    'ΠΡΟΣΒΑΣΗ ΣΕ ΟΛΟ ΤΟ FARMABASE',
    'ALERT & ΕΙΔΟΠΟΙΗΣΕΙΣ',
  ];

  const featuresPremium = [
    'ΚΑΤΑΓΡΑΦΗ ΑΓΡΟΤΕΜΑΧΙΩΝ',
    'ΗΜΕΡΟΛΟΓΙΟ ΕΡΓΑΣΙΩΝ ΑΓΡΟΥ',
    'ΠΛΗΡΕΣ ΙΣΟΖΥΓΙΟ ΘΡΕΠΤΙΚΩΝ ΟΥΣΙΩΝ',
    'ΥΠΟΛΟΓΙΣΜΟΣ ΕΦΑΡΜΟΓΗΣ ΛΙΠΑΣΜΑΤΩΝ ΒΑΣΕΙ ΕΔΑΦΟΛΟΓΙΚΩΝ ΑΝΑΛΥΣΕΩΝ',
    'ΕΝΔΕΙΞΕΙΣ ΟΣΩΝ ΑΦΟΡΑ ΤΑ ΝΟΜΙΜΑ ΟΡΙΑ ΚΑΙ ΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΣΕ ΘΡΕΠΤΙΚΑ ΣΥΣΤΑΤΙΚΑ, ΦΥΤΟΠΡΟΣΤΑΤΕΥΤΙΚΑ ΚΑΙ ΝΕΡΟ',
    'ΚΑΤΑΝΑΛΩΣΗ ΥΔΑΤΟΣ',
    'ΕΚΤΙΜΗΣΗ ΑΝΘΡΑΚΙΚΟΥ ΑΠΟΤΥΠΩΜΑΤΟΣ',
    'ΕΦΑΡΜΟΓΗ ΑΡΔΕΥΣΕΩΝ',
    'ΠΛΗΡΗ ΠΡΟΣΒΑΣΗ ΣΤΗ FARMACON:FARMABASE | FARMALEARN | FARMATOOLS',
    'ΠΡΟΓΝΩΣΗ ΚΑΙΡΟΥ ΜΕ ΒΕΛΤΙΣΤΟΥΣ ΧΡΟΝΟΥΣ ΨΕΚΑΣΜΟΥ (48 ΩΡΩΝ)',
    'ALERT & ΕΙΔΟΠΟΙΗΣΕΙΣ',
  ];

  const subscriptions = [
    {
      id: '1',
      headerTitle: basicheaderTitle,
      title: basicTitle,
      listData: featuresBasic,
      description: basicDescription,
    },
    {
      id: '2',
      headerTitle: premiumHeaderTitle,
      title: premiumTitle,
      listData: featuresPremium,
      description: premiumDescription,
    },
  ];

  return (
    <Flex gap="small" vertical style={{ marginTop: 50, marginBottom: 70 }}>
      <Row gutter={[48, 8]} justify="center" style={{ ...baseStyle.mainRow }}>
        {subscriptions.map((subscription) => (
          <Col xs={22} md={11} xl={8} key={subscription.id}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              whileHover={{ scale: 1.12 }}
              variants={{
                hidden: { opacity: 0, y: -200 },
                visible: {
                  opacity:
                    subscription?.title === accountSubscription ||
                    !accountSubscription
                      ? 1
                      : 0.7,
                  y: 0,
                  transition: { duration: 1.5 },
                  scale: subscription?.title === accountSubscription ? 1.15 : 1,
                },
              }}
              viewport={{ once: true }}
            >
              <Card
                styles={{
                  header: { ...subscriptionCardHeader, textAlign: 'center' },
                  body: {
                    ...cardStyle,
                    ...subscriptionCardBody,
                  },
                  actions: { backgroundColor: baseColors.fsBlue },
                }}
                style={{
                  backgroundColor: 'transparent',
                }}
                actions={[
                  <Row
                    key={1}
                    justify="center"
                    onClick={() => {
                      !accountSubscription &&
                        navigate('/info#contactUs', {
                          state: { package: subscription?.title },
                        });
                    }}
                  >
                    <Col
                      span={12}
                      style={{ ...actionCardCol, textAlign: 'center' }}
                    >
                      <Text
                        strong
                        style={{
                          fontSize: 16,
                          color: baseColors.fsLightBlue,
                        }}
                      >
                        {contactUs}
                      </Text>
                    </Col>
                  </Row>,
                ]}
                title={
                  <Row justify="center" align="middle">
                    <Col
                      span={16}
                      style={{
                        ...titleCardCol,
                        textAlign: 'center',
                      }}
                    >
                      {subscription.headerTitle}
                    </Col>
                  </Row>
                }
              >
                <Row justify="center">
                  <Text
                    strong
                    style={{
                      color: baseColors.fsLightBlue,
                      fontSize: 30,
                    }}
                  >
                    AGROS
                  </Text>
                </Row>
                <Row justify="center">
                  <Text
                    strong
                    style={{
                      color: baseColors.fsLightBlue,
                      fontSize: 20,
                    }}
                  >
                    {subscription?.title}
                  </Text>
                </Row>
                <Row justify="center">
                  <Col span={6}>
                    <Divider
                      style={{
                        backgroundColor: baseColors.fsLightBlue,
                        margin: 4,
                      }}
                    />
                  </Col>
                </Row>
                <Row justify="center" align="middle">
                  <Text
                    style={{
                      color: baseColors.fsLightBlue,
                      fontSize: 20,
                    }}
                  >
                    {subscription?.description}
                  </Text>
                </Row>
                <List
                  style={{ padding: 3, margin: 25 }}
                  size="small"
                  split={false}
                  dataSource={subscription.listData}
                  renderItem={(subscription) => (
                    <List.Item style={{ padding: 3 }}>
                      <List.Item.Meta
                        avatar={
                          <CheckOutlined
                            style={{
                              color: baseColors.fsLightBlue,
                              marginRight: '5px',
                            }}
                          />
                        }
                        title={
                          <Text
                            strong
                            style={{
                              color: baseColors.fsLightBlue,
                              fontSize: 13,
                            }}
                          >
                            {subscription}
                          </Text>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

export default Subscriptions;
