import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  InputNumber,
  Row,
  Select,
  App,
} from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import {
  NamedItem,
  LandParcel,
  IrrigationEvent,
  CalendarEventContent,
} from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { getData, mutateData } from '../../../shared/services/apiServices';
import { useMutation, useQuery } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import type { Dayjs } from 'dayjs';
import { Method } from 'axios';
import dayjs from 'dayjs';
import CommonFormItems from './CommonFormItems';
import useReminderMutation from '../../../shared/hooks/useEventReminderMutation';
import EventReminder from '../../EventReminder/EventReminder';
import { CultivationActivity } from '../../../shared/context/activityMutation';

type Props = {
  selectedCultivation: LandParcel | undefined;
  selectedDate: Dayjs | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
  viewOnly: boolean;
};
const IrrigationForm: FC<Props> = ({
  selectedCultivation,

  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
  viewOnly,
}) => {
  const { message } = App.useApp();
  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const { request } = useCreateAxiosInstance();
  const { mutate: mutateReminder } = useReminderMutation();
  const [form] = Form.useForm();
  const [irrigationMethodId, setIrrigationMethodId] = useState<number>();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const { data: irrigationData } = useQuery({
    queryKey: ['irrigationData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELIRRIGATION}/${selectedEvent?.id}`,
      ),
    select(data: { data: IrrigationEvent }) {
      return data.data;
    },
    enabled: !!selectedEvent,
  });
  useEffect(() => {
    if (irrigationData) {
      setIrrigationMethodId(irrigationData.irrigationMethodId);
      form.setFieldsValue({
        cultivation: !selectedEvent?.reminderId
          ? selectedEvent?.landParcelCultivation
          : selectedEvent?.cultivationName,
        landParcelId: selectedEvent?.landParcelId,
        startDate: !selectedEvent?.reminderId
          ? dayjs(irrigationData.startDate)
          : undefined,
        duration: irrigationData.duration,
        quantity: irrigationData.quantity,
        cubic: irrigationData.cubic,
        irrigationMethodId: irrigationData.irrigationMethod,
      });
    }
  }, [irrigationData]);
  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        startDate: selectedDate?.startOf('day'),
      });
    }
  }, [selectedCultivation]);
  const { data: irrigationMethodItems, isLoading } = useQuery({
    queryKey: ['irrigationMethodItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.IRRIGATIONMETHOD),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: IrrigationEvent & { reminderDate: number }) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELIRRIGATION,
        formValues,
        apiMethod,
      ),
    onSuccess(data, variables) {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
      variables?.reminderDate &&
        mutateReminder({
          eventId: data.data,
          eventType: endPoints.CALENDAR.LANDPARCELIRRIGATION,
          reminderDate: variables.reminderDate,
        });
    },
  });
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      startDate: dayjs(values.startDate?.$d).startOf('day').valueOf(),
      irrigationMethodId: irrigationMethodId,
      id: selectedEvent?.id,
      reminderDate: values?.reminderDate?.$d.getTime(),
    };

    mutate(formattedValues);
  };
  if (isLoading || isPending) return <LoadingSpin />;
  return (
    <>
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <CommonFormItems />
        <Row>
          <Col xs={12}>
            <Form.Item
              name="startDate"
              label="Ημ/νια  Έναρξης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια  Έναρξης"
                style={fullWidth}
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="duration"
              label="Διάρκεια (Ώρες)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Διάρκεια (Ώρες)"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="quantity"
              label="Ποσότητα/εκτάριο m³"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Ποσότητα/εκτάριο m³"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="cubic"
              label="Κυβικά / ώρα m³ γεώτρησης"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Κυβικά/ώρα m³"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="irrigationMethodId"
              label="Μέθοδος Άρδευσης"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={irrigationMethodItems}
                placeholder="Μέθοδος Άρδευσης"
                disabled={viewOnly}
                onSelect={(value) => {
                  setIrrigationMethodId(value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {!viewOnly && (
          <EventReminder
            reminderDate={
              apiMethod == 'put' ? irrigationData?.reminderDate : null
            }
          />
        )}
        {apiMethod == 'post' && (
          <Row justify="center">
            <Button htmlType="submit">Προσθήκη</Button>
          </Row>
        )}
        {!viewOnly && apiMethod == 'put' && (
          <Row justify="center">
            <Button htmlType="submit">Αποθήκευση</Button>
          </Row>
        )}
      </Form>
    </>
  );
};

export default IrrigationForm;
