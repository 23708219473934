import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  App,
} from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import {
  CalendarEventContent,
  LandParcel,
  QualityCharacteristicEvent,
} from '../../../types/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mutateData, getData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Method } from 'axios';

import CommonFormItems from './CommonFormItems';
import EventReminder from '../../EventReminder/EventReminder';
import useReminderMutation from '../../../shared/hooks/useEventReminderMutation';
import { CultivationActivity } from '../../../shared/context/activityMutation';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
type Props = {
  selectedCultivation: LandParcel | undefined;
  selectedDate: Dayjs | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
  viewOnly: boolean;
};
const QualityCharacteristicsForm: FC<Props> = ({
  selectedCultivation,
  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
  viewOnly,
}) => {
  const { message } = App.useApp();
  const { request } = useCreateAxiosInstance();

  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const { mutate: mutateReminder } = useReminderMutation();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const { data: qualityCharacteristicsData, isLoading } = useQuery({
    queryKey: ['qualityCharacteristicsData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELQUALITYCHARACTERISTIC}/${selectedEvent?.id}`,
      ),
    select(data: { data: QualityCharacteristicEvent }) {
      return data.data;
    },
    enabled: !!selectedEvent,
  });
  useEffect(() => {
    if (qualityCharacteristicsData) {
      form.setFieldsValue({
        cultivation: !selectedEvent?.reminderId
          ? selectedEvent?.landParcelCultivation
          : selectedEvent?.cultivationName,
        landParcelId: selectedEvent?.landParcelId,
        fruitSugars: qualityCharacteristicsData.fruitSugars,
        fruitHardness: qualityCharacteristicsData.fruitHardness,
        fruitColor: qualityCharacteristicsData.fruitColor,
        measurementDate: !selectedEvent?.reminderId
          ? dayjs(qualityCharacteristicsData.measurementDate)
          : undefined,
        expectedHarvestStartDate: dayjs(
          qualityCharacteristicsData.expectedHarvestStartDate,
        ),
        expectedHarvestEndDate: dayjs(
          qualityCharacteristicsData.expectedHarvestEndDate,
        ),
        allowableHarvestDate: dayjs(
          qualityCharacteristicsData.allowableHarvestDate,
        ),
      });
    }
  }, [qualityCharacteristicsData]);
  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        measurementDate: selectedDate?.startOf('day'),
      });
    }
  }, [selectedCultivation]);

  const { mutate, isPending } = useMutation({
    mutationFn: (
      formValues: QualityCharacteristicEvent & { reminderDate: number },
    ) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELQUALITYCHARACTERISTIC,
        formValues,
        apiMethod,
      ),
    onSuccess(data, variables) {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
      variables?.reminderDate &&
        mutateReminder({
          eventId: data.data,
          eventType: endPoints.CALENDAR.LANDPARCELQUALITYCHARACTERISTIC,
          reminderDate: variables.reminderDate,
        });
    },
  });
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      measurementDate: dayjs(values.measurementDate?.$d)
        .startOf('day')
        .valueOf(),
      expectedHarvestStartDate: dayjs(values.expectedHarvestStartDate?.$d)
        .startOf('day')
        .valueOf(),
      expectedHarvestEndDate: dayjs(values.expectedHarvestEndDate?.$d)
        .startOf('day')
        .valueOf(),
      allowableHarvestDate: dayjs(values.allowableHarvestDate?.$d)
        .startOf('day')
        .valueOf(),
      id: selectedEvent?.id,
      reminderDate: values?.reminderDate?.$d.getTime(),
    };

    mutate(formattedValues);
  };
  if (isLoading || isPending) return <LoadingSpin />;
  return (
    <>
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <CommonFormItems />
        <Row>
          <Col xs={12}>
            <Form.Item
              name="measurementDate"
              label="Ημ/νία μέτρησης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία μέτρησης"
                style={fullWidth}
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="fruitSugars"
              label="Σάκχαρα καρπού"
              rules={[requiredRuleConfig]}
            >
              <Input placeholder="Σάκχαρα καρπού" disabled={viewOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="fruitHardness"
              label="Σκληρότητα καρπού"
              rules={[requiredRuleConfig]}
            >
              <Input placeholder="Σκληρότητα καρπού" disabled={viewOnly} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="fruitColor"
              label="Χρώμα καρπού"
              rules={[requiredRuleConfig]}
            >
              <Input placeholder="Χρώμα καρπού" disabled={viewOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col xs={12}>
            <Form.Item
              name="expectedHarvestStartDate"
              label="Αναμενόμενη Ημ/νία έναρξης συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία έναρξης συγκομιδής"
                style={fullWidth}
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="expectedHarvestEndDate"
              label="Αναμενόμενη Ημ/νία λήξης συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία λήξης συγκομιδής"
                style={fullWidth}
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col xs={12}>
            <Form.Item
              name="allowableHarvestDate"
              label="Ημ/νία επιτρεπόμενης συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νία επιτρεπόμενης συγκομιδής"
                style={fullWidth}
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={12}></Col>
        </Row>
        {!viewOnly && (
          <EventReminder
            reminderDate={
              apiMethod == 'put'
                ? qualityCharacteristicsData?.reminderDate
                : null
            }
          />
        )}
        {apiMethod == 'post' && (
          <Row justify="center">
            <Button htmlType="submit">Προσθήκη</Button>
          </Row>
        )}
        {!viewOnly && apiMethod == 'put' && (
          <Row justify="center">
            <Button htmlType="submit">Αποθήκευση</Button>
          </Row>
        )}
      </Form>
    </>
  );
};
export default QualityCharacteristicsForm;
